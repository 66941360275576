import { fetchApi } from './fetch';

export const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const baseURL = 'https://maps.googleapis.com/maps/api/geocode/json';

export const reverseGeocoding = async (lat, lng) => {
    const url = `${baseURL}?latlng=${lat},${lng}&key=${apiKey}`;
    const geoObj = await fetchApi.get(url);

    switch (geoObj.status) {
        case 'UNKNOWN_ERROR':
            return reverseGeocoding(lat, lng);
        case 'OK':
            return geoObj.results[0]['formatted_address'];
        default:
            return '';
    }
}
