import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import { GoCheck } from 'react-icons/go';
import ButtonWithIcon from './ButtonWithIcon';
import CustomAlert from './CustomAlert';
import { getAllUsers, addOwnerToDevice } from '../../service/fleetsenseApiClient';
import { map, take } from 'lodash';

import '../../styles/AddOwnerToast.scss';

const AddOwnerToast = props => {

    const { deviceId, variant = "primary", show, onClose ,xs = 7, left, top, backgroudColor = "white" } = props;

    const [ optionsList, setOptionsList ] = useState([]);
    const [ showAlert, setShowAlert ] = useState(false);
    const [ alertDetails, setAlertDetails ] = useState(false);
    const [ value, setValue ] = useState('');
    const [ selected, setSelected ] = useState(false);

    const toastStyle = {
        left: `${left-10}px`,
        top: `${top}px`,
        position: 'absolute',
        background: backgroudColor
    }

    useEffect(() => {
        if (value && value !== selected) {
            callPopulateOptionsApi();   
        }
        // setSelected(value);  
    }, [value])

    const callPopulateOptionsApi = async () => {
        try {
            const res =  await getAllUsers(null, null, `email@=${value}`, null);
            setOptionsList(res.data);
        }
        catch (e) {
            setShowAlert(true);
            setAlertDetails({ ...alertDetails, variant: "danger", message: "Unable to load owners list." });
        }   
    }

    const callSubmissionApi = async () => {
        try {
            await addOwnerToDevice(deviceId, value);
            setShowAlert(true);
            setAlertDetails({ addOwnerToDeviceError: false, variant: "success", message: "Owner was added to device." });
        }
        catch (e) {
            setShowAlert(true);
            setAlertDetails({ addOwnerToDeviceError: true, variant: "danger", message: "Unable to assign owner to device." });
        }   
    }

    const onSelection = e => {
        const searchVal = e.target.innerText;

        setValue(searchVal);
        setSelected(searchVal);
        setOptionsList([]);   
    }

    const renderSearchResults = () => {
        if (!value || (optionsList.length === 0  && value !== selected)) {
            return <span>No results</span>;
        }
        else if (optionsList && optionsList.length > 0 && value !=="") {
            return (
                <ul className="owners-options-list">
                    {take(map(optionsList, (owner, index) =>
                        <li key={owner.email + index} className="owner-option" onClick={onSelection}>{owner.email}</li>)
                    ,10)}
                </ul>
            );
        }
    }

    const onSubmission = () => {
        callSubmissionApi();
    }
    
    const onCloseAlert = () => {
        setShowAlert(false);
        onClose();
        //if error has been occurred prevent page refresh
        if (!alertDetails.addOwnerToDeviceError)
        refreshPage();
    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    return (
        <Toast show={show} xs={xs} onClose={onClose} style={toastStyle}>
            <Toast.Header>Associate owner to device</Toast.Header>
            <Toast.Body>
                <Form>
                    <Form.Row>
                        <Col xs={10}>
                            <Form.Control size="sm" type="text" value={value} onChange={e => setValue(e.target.value)}/>
                        </Col>
                        <Col xs={2}>
                            <ButtonWithIcon variant={variant} height={28} width={28} iconSize={18} onClick={onSubmission}>
                                <GoCheck />
                            </ButtonWithIcon>
                        </Col>
                    </Form.Row>
                </Form>
                {renderSearchResults()}
                <CustomAlert variant={alertDetails.variant} show={showAlert} message={alertDetails.message} onClose={onCloseAlert} timeout={3000} />
            </Toast.Body>
        </Toast>
    )
}

export default AddOwnerToast;