import React from 'react';
import { IconContext } from "react-icons";

import '../../styles/ButtonWithIcon.scss';

const ButtonWithIcon = props => {

    const { type, text, variant, disabled = false, width = 140, height = 50, iconSize = 18, textSize=18, onClick, children, customStyles = {} } = props;

    return (
        <button type={type ?? "button"} className={`btn-with-icon ${variant} ${disabled? 'disabled' : ''}`} style={{ width: `${width}px`, height: `${height}px`, ...customStyles }} onClick={!disabled && onClick ? onClick : null}>
            <div className="btn-with-item-content">
                <div className="icon-container">
                    <IconContext.Provider value={{ size: iconSize }}>
                        {children}
                    </IconContext.Provider>
                </div>
                <span className="text-container" style={{fontSize: textSize}}>{text}</span>
            </div>
        </button>
    )

}

export default ButtonWithIcon;