import React from 'react';
import '../../styles/EventDetailsPopover.scss';
import { convertISODateStringToFormattedDateString } from '../../utils/dateUtil';
import { keys } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
const EventDetails = ({ eventData }) => {

  const prepareEventData = data => {
    return {
      'Type': `${data.eventType} ${data.eventAttr1 ? ` - ${data.eventAttr1}` : ''}`,
      'Unit ID': data.deviceID,
      'Trip ID': data.tripID,
      'Time': convertISODateStringToFormattedDateString(data.eventDateTime),
      'Location': data.lastKnownStreetAddress,
      'Loc Time': convertISODateStringToFormattedDateString(data.locationTime),
      //convert m/s to km/h units formula is: m/s * 3.6 = km/h
      'Speed': data.speed ? `${Math.round(data.speed * 3.6)}Km/h` : 'unavailable speed',
      'Heading': data.headingAngle ? `${data.headingAngle.toFixed(1)}${String.fromCharCode(176)}` : 'unavailable angle',
    }
  }
  const eventDisplayData = prepareEventData(eventData);
  return (
    <div className="event-details-rows-container">
      {eventDisplayData && keys(eventDisplayData).map(key => {
        return (
          <Container key={key}>
            <Row className='row'>
              <Col xs={4} >
                <div className="event-data-item-key">
                  <span>{key}</span>
                </div>
              </Col>
              <Col xs={8} >
                <div className="event-data-item-value">
                  <span style={key === 'Event Type' ? { fontWeight: "bold" } : null}>{eventDisplayData[key]}</span>
                </div>
              </Col>
            </Row>
          </Container>
        )
      }
      )}
    </div>);
};


export default EventDetails;