import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../context/auth";

function ProtectedRoute({ component: Component, ...rest }) {
  const { isLoggedIn } = useAuth();
  const { currentUser } = {...rest}

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} currentUser={currentUser} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;