import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import { GoCheck } from 'react-icons/go';
import ButtonWithIcon from './ButtonWithIcon';
import CustomAlert from './CustomAlert';
import { setPasswordToUser } from '../../service/fleetsenseApiClient';
import { passwordIsInvalid, invalidPasswordMessage } from '../../utils/passwordValidationUtil';

import '../../styles/SetPasswordToast.scss';

const SetPasswordToast = props => {
    
    const { email, variant = "primary", show, onClose ,xs = 7, left, top, backgroudColor = "white" } = props;

    const [ showAlert, setShowAlert ] = useState(false);
    const [ alertDetails, setAlertDetails ] = useState(false);
    const [ password, setPassword ] = useState('');

    const toastStyle = {
        left: `${left}px`,
        top: `${top + 5}px`,
        position: 'absolute',
        background: backgroudColor
    }

    const callSubmissionApi = async () => {

        if(
            passwordIsInvalid(password)
        ) 
        {
            setShowAlert(true);
            setAlertDetails({ variant: "danger", message: invalidPasswordMessage });
            return;
        }

        try {
            await setPasswordToUser(email, password);
            setShowAlert(true);
            setAlertDetails({ variant: "success", message: "Password was successfuly set." });
        }
        catch (e) {
            setShowAlert(true);
            setAlertDetails({ variant: "danger", message: "Unable to set password." });
        }   

        setPassword('')
    }

    const onSubmission = (e) => {
        e.preventDefault();
        callSubmissionApi();
    }

    const onCloseAlert = () => {
        setShowAlert(false);
        onClose();
    }

    return (
        <div className="set-password-wrapper">
            {show && 
                <Toast xs={xs} onClose={onClose} style={toastStyle} onSubmit={onSubmission}>
                    <Toast.Header>Set password to user</Toast.Header>
                    <Toast.Body>
                        <Form>
                            <Form.Row>
                                <Col xs={9}>
                                    <Form.Control size="sm" type="password"  value={password} onChange={e => setPassword(e.target.value)} 
                                        title={invalidPasswordMessage} 
                                        required 
                                    />
                                </Col>
                                <Col xs={2}>
                                    <ButtonWithIcon type="submit" variant={variant} height={28} width={28} iconSize={18} >
                                        <GoCheck />
                                    </ButtonWithIcon>
                                </Col>
                            </Form.Row>
                        </Form>
                        <CustomAlert variant={alertDetails.variant} show={showAlert} message={alertDetails.message} onClose={onCloseAlert} timeout={3000} />
                    </Toast.Body>
                </Toast>
            }
        </div>
    )
}

export default SetPasswordToast;