import React, { useState, useEffect }  from 'react';
import '../styles/Login.scss';
import cipiaLogo from '../assets/cipia-logo-white.png';
import Button from 'react-bootstrap/Button';
import { login } from '../service/fleetsenseApiClient';
import { useAuth } from '../context/auth';

const Login = (props) => {

    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loginSuccess, setLoginSuccess ] = useState(false);
    const [ wrongCradentials, setWrongCradentials ] = useState(false);

    const { setIsLoggedIn } = useAuth();

    useEffect(() => {
        document.body.classList.add('bg');     
    }, []);

    useEffect(() => {
        if (isProcessing) {
            apiCall();
        }
    }, [isProcessing]);

    useEffect(() => {
        if (loginSuccess) {
            setIsLoggedIn();
            document.body.classList.remove('bg');
            props.history.push('/app');
        }
    }, [loginSuccess])

    const apiCall = async () => {
        try {
            const res =  await login(email, password);
            const token = res.token;

            if (token) {
                setIsProcessing(false); 
                setLoginSuccess(true);
                setWrongCradentials(false);
            } else {
                setIsProcessing(false); 
                setLoginSuccess(false);
            }

             
        } catch (e) {
            setEmail('');
            setPassword('');  
            setWrongCradentials(true);   
            setIsProcessing(false);       
        }
        
    };

    const onEmailChanged = e => {
        setWrongCradentials(false);
        setEmail(e.target.value);
    }

    const onPasswordChanged = e => {
        setWrongCradentials(false);
        setPassword(e.target.value);
    }

    const onLoginClicked = (e) => {
        e.preventDefault();
        setIsProcessing(true);
    }

    return (
        <div className="background-container">
            <div className="login-container row align-items-center">
                <form className="login-form" onSubmit={onLoginClicked}>
                    <div className="login-form-title">Cipia-FS10</div>

                    <div className="form-login-error" style={wrongCradentials ? {visibility: "visible"} : {visibility: "hidden"}}>Wrong email or password</div>

                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="E-mail" value={email} onChange={onEmailChanged} />
                    </div>

                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" value={password} onChange={onPasswordChanged} />
                    </div>

                    <Button type="submit" variant="secondary" disabled={isProcessing}>Login</Button>
                </form>
                <div className="login-logos">
                    <div className="img-container">
                        <img src={cipiaLogo} alt="cipia logo" />
                    </div>                
                </div>
            </div>
        </div>
    );
}

export default Login;