export const convertISODateStringToFormattedDateString = dateString => {
    return dateString ? convertDateToFormattedDateString(new Date(dateString)) : "";
}

export const convertISODateObjectToFormattedDateString = dateObj => {
    return convertDateToFormattedDateString(dateObj);
}

export const convertDateStringToISODate = dateStr => {
    if(!dateStr)
        return;

    const splittedDate = dateStr.split("/");
    const [ day, month ] = splittedDate;
    const [ year, rest ] = splittedDate[2].split(' ');
    const [ hours, minutes, seconds ] = rest.split(':');
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const tzo = - date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    
    const pad = function(num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };

    const dateStrWithTimezone = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
        
    const utcDateStr = new Date(dateStrWithTimezone).toUTCString();

    return new Date(utcDateStr).toISOString();
}

const convertDateToFormattedDateString = date => {
    if(!date)
        return;
    // adjust 0 before single digit date
    const day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let year = date.getFullYear();

    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);

    const formattedDate =  `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    // return date & time in YYYY-MM-DD HH:MM:SS format
    return isNaN(date) || formattedDate === '01/01/1 01:01:01' ? '' : formattedDate;
}
