import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import DateRange from './ui/DateRange';
import IconWithLabel from './ui/IconWithLabel';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';
import ButtonWithIcon from './ui/ButtonWithIcon';
import { Picky } from 'react-picky';
import WithTooltip from './ui/WithTooltip';

import 'react-picky/dist/picky.css';
import '../styles/Filters.scss';
import { validateFilterInput } from '../utils/filtersUtil';

const mediumScreenSize = 1700;

function filtersPropsAreEqual(prevProps, nextProp) {
    return prevProps.columns === nextProp.columns;
}


export function Filters({ columns, onFiltersChangedCallback }) {

    const barsHeight = 2.2;

    const [currentFilters, setCurrentFilters] = useState({});
    const [clearDropDownFilter, setClearDropDownFilter] = useState(false);
    const [filtersIsOpen, setFiltersIsOpen] = useState(false);
    // const [maxFilterMultiValuesToDisplay, setMaxFilterMultiValuesToDisplay] = useState(false);
    const [filtersHeight, setFiltersHeight] = useState(barsHeight);
    const [clearDateRange, setClearDateRange] = useState(false);
    const [currentMultiSelectValues, setCurrentMultiSelectValues] = useState({});

    useEffect(() => {
        //remove all empty values in filters obj
        const currentFiltersCopy =
            Object.entries(currentFilters)
                .filter(keyValueArray => !!keyValueArray[1])
                .reduce((newObj, [a, b]) => {
                    newObj[a] = b;
                    return newObj;
                }, {});

        setClearDateRange(false);
        onFiltersChangedCallback(currentFiltersCopy);
    }, [currentFilters]);

    useEffect(() => {
        onFiltersClicked();
    }, []);

    const onFilterChanged = (e, filterName) => {
        let filters, isValid;

        if (e.target) {
            const filterEl = e.target;
            const filterValue = e.target.value;
            const filterName = filterEl.getAttribute("filtername");

            isValid = validateFilterInput(filterEl);
            filters = { ...currentFilters, [filterName]: isValid ? filterValue : '' };
        }
        else {
            filters = filterName ? { ...currentFilters, [filterName]: e } : { ...currentFilters, ...e };
        }

        if (!filters.to) {
            delete filters.to
        }

        if (!filters.from) {
            delete filters.from
        }

        setCurrentFilters(filters);
    }

    const clearFilters = () => {

        // logic for handeling picky component filter clear 
        let pickyDropdowns = []
        const pickyComponents = document.getElementsByClassName('picky');
        const pickyArr = Array.from(pickyComponents);

        pickyArr.map((pickyComponent) => {
            const dropdown = pickyComponent.getElementsByClassName('picky__dropdown')[0];
            pickyDropdowns.push(dropdown);
            dropdown.style.display = 'none';

            const pickyButton = pickyComponent.querySelector('button');
            const pickyIsOpen = pickyComponent.getAttribute('aria-expanded');

            if (pickyIsOpen === 'false' || !pickyIsOpen) {
                pickyButton.click();
            }
        })

        setTimeout(() => {
            document.body.click();
            document.body.click();
            pickyDropdowns.map(dropdown => dropdown.style.display = 'inherit')
        }, 0)

        //
        setCurrentFilters({});
        setClearDateRange(true);
        setCurrentMultiSelectValues({});
    }

    const onFiltersClicked = () => {
        setFiltersIsOpen(!filtersIsOpen);
        setFiltersHeight(filtersIsOpen ? filtersHeight / 2 : filtersHeight * 2);
    }

    const onMultiSelectValuesChanged = (allSelected, values, filterName) => {
        //calculate how many values to show on screen according to screen width
        // const viewportWidth = window.innerWidth;
        // if(viewportWidth < mediumScreenSize){
        //     setMaxFilterMultiValuesToDisplay(2);
        // } else {
        //     setMaxFilterMultiValuesToDisplay(3);
        // }

        if (!Array.isArray(values)) {
            values = [values];
        }
         
        const newCurrentMultiSelectValues = { ...currentMultiSelectValues, [filterName]:  values };

        setCurrentMultiSelectValues(newCurrentMultiSelectValues);
        onFilterChanged(allSelected ? "" : values, filterName);
    }

    const renderFilters = columns => {
        return (
            <div className="table-filters-container">
                <Accordion defaultActiveKey="0">
                    <Card style={{ height: `${filtersHeight}rem` }}>
                        <div className="filters-label-container">
                            <Accordion.Toggle as="div" eventKey="0">
                                <div className="filters-label" onClick={onFiltersClicked}>
                                    {filtersIsOpen
                                        ? <IconWithLabel label="Filters" color={"#92D050"}><GoTriangleUp /></IconWithLabel>
                                        : <IconWithLabel label="Filters" color={"#92D050"}><GoTriangleDown /></IconWithLabel>}
                                </div>
                            </Accordion.Toggle>
                        </div>
                        <div className="filters-fields">
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Form onSubmit={e => e.preventDefault()}>
                                        <Form.Row>
                                            {columns.map((col, index) => renderFilterColumn(col, index))}
                                            <div
                                                style={{ display: "flex" }}
                                                onClick={clearFilters}
                                                onMouseEnter={() => { setClearDropDownFilter(true) }}
                                                onMouseLeave={() => { setClearDropDownFilter(false) }}
                                            >
                                                <ButtonWithIcon variant="third" text="clear" height={35}><RiDeleteBinLine /></ButtonWithIcon>
                                            </div>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Accordion.Collapse>
                        </div>
                    </Card>
                </Accordion>
            </div>
        )
    }

    const renderFilterColumn = (col, index) => {
        const { column, type, valueType, filterWidth, placeholder, filterable, filterOptions } = col;
        if (!filterable) return;

        switch (type) {
            case "dropdown":
                return (
                    <WithTooltip
                        text={placeholder ? placeholder : ""}
                        key={index}
                        sizeCalculationOffset={16}
                        externalConditionShowTooltip={currentFilters && currentFilters[column]}
                        customStyles={{ width: `${filterWidth}%` }}
                    >
                        <Col>
                            <Form.Control as="select" size="sm" defaultValue="placeholder" value={currentFilters[column]} filtername={column} onChange={onFilterChanged}>
                                <option value="placeholder" disabled hidden>{placeholder}</option>
                                {filterOptions.map((option, index) => <option key={index} className="dropdown-filter-option">{option}</option>)}
                            </Form.Control>
                        </Col>
                    </WithTooltip>
                )
            case "date":
                return (
                    <Col style={{ width: `${filterWidth}%` }} key={index}>
                        <DateRange
                            customStyles={{ width: `${filterWidth}%` }}
                            onChange={onFilterChanged}
                            clear={clearDateRange}
                            fromPlaceholder={`${placeholder} From`}
                            toPlaceholder={`${placeholder} To`}
                        />
                    </Col>
                )
            case "multiselect":
                return (
                    <WithTooltip
                        text={placeholder ? placeholder : ""}
                        key={index}
                        customStyles={{ width: `${filterWidth}%` }}
                        externalConditionShowTooltip={currentMultiSelectValues && currentMultiSelectValues[column]}
                    >
                        <Col>
                            <Picky
                                id="picky"
                                options={filterOptions}
                                labelKey="label"
                                valueKey="value"
                                value={currentMultiSelectValues[column] ?? []}
                                multiple={true}
                                includeSelectAll={true}
                                includeFilter={true}
                                onChange={values => onMultiSelectValuesChanged(values.length === filterOptions.length, values, column)}
                                dropdownHeight={300}
                                placeholder={placeholder}
                                // numberDisplayed={maxFilterMultiValuesToDisplay}
                                numberDisplayed={column === 'eventType' ? 1 : 2}
                                clearFilterOnClose={clearDropDownFilter}
                            />
                        </Col>
                    </WithTooltip>
                )
            default:
                return (
                    <WithTooltip
                        text={placeholder ? placeholder : ""}
                        key={index}
                        customStyles={{ width: `${filterWidth}%` }}
                        externalConditionShowTooltip={currentFilters && currentFilters[column]}
                    >
                        <Col>
                            <Form.Control as="input" size="sm" value={currentFilters[column] ?? ''} value-type={valueType} placeholder={placeholder} filtername={column} onChange={onFilterChanged} />
                        </Col>
                    </WithTooltip>
                )
        }
    }

    return (
        <div>
            {columns && columns.length > 0 && renderFilters(columns)}
        </div>
    )

};

export const MemoizedFilters = React.memo(Filters, filtersPropsAreEqual);