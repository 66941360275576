import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import {convertISODateObjectToFormattedDateString} from '../../utils/dateUtil';
import WithTooltip from '../ui/WithTooltip';
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/DateRange.scss";

const DateRange = (props) => {

    const { onChange, clear ,fromPlaceholder, toPlaceholder } = props;
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (startDate && endDate) {
            onChange({ "from": convertISODateObjectToFormattedDateString(startDate), "to": convertISODateObjectToFormattedDateString(endDate) });
        } else if (startDate) {
            onChange({"from" : convertISODateObjectToFormattedDateString(startDate), "to" : null})
        } else {
            onChange({"from" : null, "to" : null})
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (clear) {
            setStartDate("");
            setEndDate("");
        }
    }, [clear])


    const handleHoursInDate = (newDate, dateToCompare) => {
        if (newDate && dateToCompare && dateToCompare.getTime() === newDate.getTime()) {
            if(endDate) {
                endDate.setHours(23,59,59,59);
            } else {
                newDate.setHours(23,59,59,59);
            }
        } 
    }

    const dateChangeHandler = (origin, date) => {
        
        if (origin === 'endDate') {
            handleHoursInDate(date, startDate);
            setEndDate(date);
        }

        if (origin === 'startDate') {
            handleHoursInDate(date, endDate);
            setStartDate(date);
        }  
    }

    return (
        <div className="date-range-container">
            <WithTooltip
                text={fromPlaceholder ? fromPlaceholder : ""}
                customStyles
                externalConditionShowTooltip={!!startDate}
            >
                <DatePicker 
                    selected={startDate}
                    onChange={date => dateChangeHandler('startDate', date)}
                    showTimeSelect
                    selectsStart
                    timeFormat="HH:mm"
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={fromPlaceholder}
                    className="form-control form-control-lg"
                    portalId="root"
                    dateFormat='dd/MM/yyyy HH:mm:00'
                />
            </WithTooltip>


            <WithTooltip
                text={toPlaceholder ? toPlaceholder : ""}
                customStyles
                externalConditionShowTooltip={!!endDate}
            >
                <DatePicker 
                    selected={endDate}
                    onChange={date => dateChangeHandler('endDate', date)}
                    showTimeSelect
                    selectsEnd
                    timeFormat="HH:mm"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText={toPlaceholder}
                    className="form-control form-control-lg"
                    portalId="root"
                    dateFormat='dd/MM/yyyy HH:mm:00'
                    style={{ marginRight: '10px' }}
                />
            </WithTooltip>
        </div>
    ) 
}

export default DateRange;