import React from 'react';
import { Col, Container, Row } from "react-bootstrap"
import { isNumber } from 'lodash';
import { BiX, BiErrorCircle } from 'react-icons/bi';
import SimpleMap from "./SimpleMap";
import IconWithLabel from './IconWithLabel';
import '../../styles/EventDetailsPopover.scss';

const EventMap = ({ lat, lng }) => {

    const convertedLat = Number(lat);
    const convertedLng = Number(lng);

    return (
        <div className="map-container">

            {(convertedLat && isNumber(convertedLat) && convertedLng && isNumber(convertedLng)) ?
                <SimpleMap lat={convertedLat} lng={convertedLng} ></SimpleMap> :
                <div className="no-location">
                    <IconWithLabel label={'No location was provided'} color='#b30000'>
                        <BiErrorCircle />
                    </IconWithLabel>
                </div>
            }
        </div>

    )

}

export default EventMap;