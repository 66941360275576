import { createContext } from 'react';

export const TableDataContext = createContext({
    fetchCallback: () => {},
    setFetchCallback: () => () => {},
    fetchCallbackParams: [],
    setFetchCallbackParams: () => {},
    updateEventsData: false,
    setUpdateEventsData: () => {}
});

export default TableDataContext;
