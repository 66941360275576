import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

const style = {
    marginTop: '5px'
}

const CustomAlert = ({ variant, message, show, onClose, timeout }) => {

    const [timer, setTimer] = useState(null);

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        clearTimeout(timer);
        
        if (timeout && show) {
            let timeoutFunction = setTimeout(() => onClose(), timeout);
            setTimer(timeoutFunction);
        }
    }, [show])
    
    return (
        <Alert style={style} variant={variant} show={show} onClose={onClose} dismissible>
            <p>
                {message}
            </p>    
        </Alert> 
    )
}

export default CustomAlert;