import React, { useState, useEffect } from 'react';

import { getVersion } from '../service/fleetsenseApiClient';
import '../styles/Footer.scss';

const Footer = (props) => {

    const [ version, setVersion ] = useState(null);

    useEffect(() => {
        apiCall();
    })

    const apiCall = async () => {
        const version = await getVersion();
        setVersion(version.version);
    }

    
    return(
        <div className="footer" style={{display: version ? 'block' : 'none' }} >  
              Cipia-FS10 version {version  ? version : ""}
        </div>
    );
    
}

export default Footer;