import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CustomModal = props => {

    const { show, headingText, text, onClose = () => {}, onConfirmation, size='md', okOnly = false } = props;

    const handleOk = () => {
        onConfirmation();
        onClose();
    }

    return (
        <Modal show={show} onHide={onClose} size={size} centered>
            <Modal.Header closeButton>
                <Modal.Title>{headingText}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{text}</Modal.Body>

            <Modal.Footer>
                {okOnly ? 
                    null
                    :
                    <Button variant="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                }

                <Button variant="primary" onClick={handleOk}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default CustomModal;