import React, { useRef, useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import '../../styles/TextWithOverflowTooltip.scss';

const TextWithOverflowTooltip = props => {

    const { text } = props;

    const textElementRef = useRef();

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

    const [show, setShow] = useState(false);
    const [hover, setHover] = useState(false);
    const [placement, setPlacement] = useState(null);

    const compareSize = () => {
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const { left, right } = textElementRef.current.getBoundingClientRect();
        const tooltipWidth = 0.15 * vw;
        const calcPlacement = (vw - right) > tooltipWidth ? 'right' : (left > tooltipWidth ? 'left' : 'top');
        
        setShow(compare);
        setPlacement(calcPlacement);
    };
    
    return (
        <OverlayTrigger
            show={show && hover}
            trigger={['hover', 'focus']}
            placement={placement ?? 'right'}
            flip={true}
            overlay={
                <Tooltip id="overflow-tooltip">
                    {text}
                </Tooltip>
            }
        >
        <div
            ref={textElementRef}
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {text}
        </div>
        </OverlayTrigger>
    );
};

export default TextWithOverflowTooltip;