import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import TopNav from '../components/TopNav';
import Events from './Events';
import Devices from './Devices';
import Users from './Users';
import ProtectedRoute from '../components/route/ProtectedRoute';
import TableDataContext from '../context/tableDataContext';
import CustomModal from '../components/ui/CustomModal';

const Layout = (props) => {

    const { currentUser } = props;
    const { path } = useRouteMatch();

    const [ fetchParams, setFetchParams ] = useState([]);
    const [ fetch, setFetch ] = useState(null);

    const [ updateEventsData, setUpdateEventsData ] = useState(false);
    const [ logoutPopup, setLogoutPopup ] = useState(null);
    const [ sessionEnded, setSessionEnded ] = useState(false);

    useEffect(()=>{
        document.addEventListener('unauth', unauthEventHandler, false);

        return () => {
            document.removeEventListener('unauth', unauthEventHandler, false);
        }

    },[]);

    const unauthEventHandler = (e) => {
        //checking if logout event came from session end or button click
        if(e.srcElement.tagName) {
            setLogoutPopup(true);
        } else {
            setSessionEnded(true);
            setLogoutPopup(true);
        }
    }

    const setFetchCallbackParams = params => {
        setFetchParams(params);
    }

    const setFetchCallback = func => setFetch(func);

    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }

    return (
        <TableDataContext.Provider 
            value={{ 
                fetchCallback: fetch,
                setFetchCallback,
                fetchCallbackParams: fetchParams,
                setFetchCallbackParams,
                updateEventsData,
                setUpdateEventsData
            }}
        >
            <TopNav username={currentUser}/>

            <Switch>
                <ProtectedRoute path={path} exact>
                    <Redirect to={`${path}/events`}/>
                </ProtectedRoute>
                <ProtectedRoute path={`${path}/events`} component={Events} />
                <ProtectedRoute path={`${path}/devices`} component={Devices} />
                <Route path="/app/users" component={Users} />
            </Switch>

            <CustomModal 
                show={logoutPopup} 
                headingText={"Logout"} 
                text={sessionEnded ? 'Session ended, you will be logged out.' : 'Are you sure you want to logout?'} 
                okOnly={sessionEnded ? true : false}
                onClose={() => sessionEnded ? logout() : setLogoutPopup(false)} 
                onConfirmation={() => logout()} 
            />
        </TableDataContext.Provider>
    );
}

export default Layout;