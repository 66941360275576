import React from 'react';
import { GoTriangleDown, GoTriangleUp} from 'react-icons/go';
import { getViewportWidth } from '../utils/viewportUtil';

const TableHeaders = ({ columns, withCheckbox, sortBy, sortOrder, onSortBy }) => {

    //constants
    const viewportWidth = getViewportWidth();


    const calcWidthInPx = widthInPercentage => {
        return (viewportWidth * widthInPercentage) / 100;
    }

    return (
        <thead>
            <tr>
                {withCheckbox && <th style={{ width: calcWidthInPx(2), maxWidth: calcWidthInPx(2) }}></th>}
                {columns && columns.length && columns.map((header, index) => {
                    const columnWidth = calcWidthInPx(header.width);
                    return <th 
                            key={index} 
                            onClick={e => header.sortable && onSortBy(e, header.column)} 
                            style={{ width: columnWidth, maxWidth: columnWidth, ...header.sortable ? {cursor: "pointer"} : null }}
                        >
                            {header.displayName}{header.sortable && sortBy && sortBy === header.column && (sortOrder === 'DESC' ? <GoTriangleDown /> : <GoTriangleUp/>)}
                        </th>
                    })
                }
            </tr>
        </thead>
    )
}

export default TableHeaders;