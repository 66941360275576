import { assign } from 'lodash';

export const fetchApi = {
    get,
    post,
    put,
    delete: _delete
};

function get(url, headers = {}) {
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, headers = {}) {
    const requestOptions = {
        method: 'POST',
        headers: assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body = {}, headers = {}) {
    const requestOptions = {
        method: 'PUT',
        headers: assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, headers = {}) {
    const requestOptions = {
        method: 'DELETE',
        headers: headers
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {

    if(response.status === 401 && !response.url.includes('/api/auth/login')) {
        const evt = new CustomEvent('unauth');
        evt.initEvent('unauth', true, true);
        document.dispatchEvent(evt);
    }

    return response.text().then(text => {
        
        if (!response.ok) {
            const error = (text && text.message) || response.statusText;
            return Promise.reject(error);
        }
     
        const data = text && JSON.parse(text);
        return data;
    })
}