import React, { useState, useEffect, useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import cipiaLogo from '../assets/cipia-logo.png';
import '../styles/TopNav.scss';
import IconWithLabel from './ui/IconWithLabel';
import { BiCalendarStar } from 'react-icons/bi';
import { MdSettingsRemote } from 'react-icons/md';
import { HiOutlineUser, HiOutlineUsers } from 'react-icons/hi';
import { includes } from 'lodash';
import { getLogo } from '../service/fleetsenseApiClient';
import ButtonWithIcon from '../components/ui/ButtonWithIcon';
import { RiRefreshLine } from 'react-icons/ri';
import TableDataContext from '../context/tableDataContext';
import { Link } from 'react-router-dom'


const fetchButtonCustomStyles = {
    height: '36px',
    marginLeft:'30px',
    position: 'absolute'
}

const TopNav = (props) => {

    const { username } = props;
    const { updateEventsData, setUpdateEventsData } = useContext(TableDataContext);

    const [ logo, setLogo ] = useState(null);
    const [ showLogo, setShowLogo ] = useState(null);

    useEffect(() => {
        apiCall();
    }, [])

    const apiCall = async () => {
        const logo = await getLogo(username);
        setLogo(logo);
    }

    const selected = (() => {
        const location = window.location.href;

        if (includes(location, '/devices')) {
            return 1;
        }
        else if (includes(location, '/users')) {
            return 2;
        }    
        else {
            return 0;
        }
    })();
    
    const fetchNewEventsHandler = () => {
        setUpdateEventsData(!updateEventsData);
    }

    const activateLogoutPopup = (e) => {
        const evt = new CustomEvent('unauth');
        evt.initEvent('unauth', true, true);
        e.target.dispatchEvent(evt);
    }
    
    return (
        <div className="top-nav">
            <div className="logos-bar">
                <div className="img-container">
                    <img 
                        src={logo}
                        alt="logo" 
                        onError={()=>{setShowLogo(false)}}
                        onLoad={()=>{setShowLogo(true)}}
                        style={showLogo === false ? {display:'none'}:{}}
                    />
                </div>
                <div className="img-container">
                    <img src={cipiaLogo} alt="cipia logo" />
                </div>                
            </div>
            
            <Navbar bg="primary" variant="dark" expand="lg">
                
                {window.location.href.includes('events') ? 
                    <ButtonWithIcon
                        text={'Update data'}
                        customStyles = {fetchButtonCustomStyles}
                        textSize={13}
                        width={122}
                        variant={'secondary'}
                        onClick={fetchNewEventsHandler}
                    >
                        <RiRefreshLine/>
                    </ButtonWithIcon>
                        :
                    null
                }


                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" varian="tabs">
                        <div className="nav-pages-link-container">
                            <Nav.Item className={selected === 0 && "clicked"}>
                                <Nav.Link as={Link} to="/app/events">
                                    <IconWithLabel label="EVENTS">
                                        <BiCalendarStar />
                                    </IconWithLabel>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={selected === 1 && "clicked"}>
                                <Nav.Link as={Link} to="/app/devices">
                                    <IconWithLabel label="DEVICES" >
                                        <MdSettingsRemote />
                                    </IconWithLabel>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={selected === 2 && "clicked"}>
                                <Nav.Link as={Link} to="/app/users">
                                    <IconWithLabel label="USERS">
                                        <HiOutlineUsers />
                                    </IconWithLabel>
                                </Nav.Link>
                            </Nav.Item>
                        </div>
                        {/* <div className="nav-user-container">
                            
                        </div> */}
                        <div className="nav-user-container">
                            <Nav.Item>
                                <IconWithLabel label={username}><HiOutlineUser /></IconWithLabel>
                            </Nav.Item>
                            <Nav.Item>
                                <span onClick={activateLogoutPopup}>
                                    <span className='logout-span'>Logout</span>
                                </span>
                            </Nav.Item>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
    
}

export default TopNav;