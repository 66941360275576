
const specialChars = ['\'','\"','!','#','$','%','&','(',')','*','+',',','-','.','\\','/',':',';','<','=','>','?','@','[',']','^','_','\`','{','|','}','~']
export const invalidPasswordMessage = "Must contain at least one number, one uppercase letter, one lowercase letter, one special character and be  at least 6 characters."

export const passwordIsInvalid = (password) => {
    if(!password)
        return true;

    const containsSpecialChar = specialChars.filter(specialChar => password.includes(specialChar)).length > 0;

    return (
        password.length < 6 || 
        password.toLowerCase() === password || 
        password.toUpperCase() === password ||
        !/\d/.test(password) ||
        !containsSpecialChar
    );
}