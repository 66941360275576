export const getViewportWidth = () => {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

export const getViewportHeight = () => {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
}

export const getPageXOffset = () => {
    const doc = document.documentElement;
    const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);

    return left;
}

export const getPageYOffset = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    return top;
}