import React, { useState, useEffect, useContext } from 'react';
import TableWithExtensions from '../components/TableWithExtensions';
import { MemoizedFilters } from '../components/Filters';
import CustomPagination from '../components/ui/CustomPagination';
import PageTitle from '../components/ui/PageTitle';
import { getAllUsers } from '../service/fleetsenseApiClient';
import { buildFiltersString } from '../utils/filtersUtil';
import { convertISODateStringToFormattedDateString } from '../utils/dateUtil';
import { HiOutlineUsers } from 'react-icons/hi';
import TableDataContext from '../context/tableDataContext';
import { map, pick } from 'lodash';

const Users = (props) => {

    const columns = [
        {
            column: "userName",
            displayName: "User Name",
            type: "input",
            valueType: "string",
            width: 24,
            filterWidth: 19,
            placeholder: "User Name",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "email",
            displayName: "Email",
            type: "input",
            valueType: "string",
            width: 24,
            filterWidth: 19,
            placeholder: "Email",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "role",
            displayName: "Role", 
            type: "dropdown",
            valueType: "string",
            width: 24,
            filterWidth: 19,
            placeholder: "Role",
            filterable: true,
            filterOptions: ["Administrator", "Installer", "User"],
            sortable: true
        },
        {
            column: "lastAccessed",
            displayName: "Last Connected", 
            type: "date",
            valueType: "string",
            width: 24.1,
            filterWidth: 30.1,
            placeholder: "Last Connected",
            filterable: true,
            filterOptions: [],
            sortable: true
        }
    ];

    const rowsPerPage = 10;

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsData, setRowsData] = useState([]);
    const [ currentFiltersString, setCurrentFiltersString ] = useState("");
    const [ currentSorts, setCurrentSorts ] = useState("userName");
    const [ currentSortOrder, setCurrentSortOrder ] = useState("");
    const [ currentSortsString, setCurrentSortsString ] = useState("userName");
    const [ totalRows, setTotalRows ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);

    const { setFetchCallback, setFetchCallbackParams } = useContext(TableDataContext);

    useEffect(() => {
        setIsLoading(true);
        apiCall();
        setFetchCallback(() => exportDataApiCall);
        setFetchCallbackParams([null, null, currentFiltersString, currentSortsString]);
    }, [currentPage, currentFiltersString, currentSortsString]);

    useEffect(() => {
        setCurrentPage(1);
    }, [currentFiltersString]);

    useEffect(() => {
        if (currentSorts) {
            setCurrentSortsString(`${currentSortOrder}${currentSorts}`);
        }
    }, [currentSorts, currentSortOrder]);

    const prepareData = data => {
        const columnsKeys = map(columns, col => col.column);
        return map(data, dataRow => {
            const userDataRow = pick(dataRow, columnsKeys);
            userDataRow.lastAccessed = convertISODateStringToFormattedDateString(userDataRow.lastAccessed);
            return userDataRow;
        });
    }

    const apiCall = async () => {
        let res;
        
        try {
            res =  await getAllUsers(currentPage, rowsPerPage, currentFiltersString, currentSortsString);
            const data = prepareData(res.data);
            const header = res.header;

            setTotalRows(header.totalCount);
            setRowsData(data);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const exportDataApiCall = async (page, pageSize, filters, sorts) => {
        const res =  await getAllUsers(page, pageSize, filters, sorts);
        const data = res.data;

        return data;
    };

    const onGoToPageCallback = pageNum => {
        setCurrentPage(pageNum);
    }

    const onFiltersChangedCallback = filters => {
        setCurrentFiltersString(buildFiltersString(columns, filters));
    }

    const onSortByCallback = columnName => {
        setCurrentSorts(columnName);
    }

    const onSortOrderCallback = order => {
        setCurrentSortOrder(order === 'DESC' ? "-" : "");
    }

    return (
        <div className="users-container" style={{ display: 'flex', flexDirection: 'column' }}>
            <PageTitle title={"USERS"} variant={"primary"} >
                <HiOutlineUsers />
            </PageTitle>
            <MemoizedFilters columns={columns} onFiltersChangedCallback={onFiltersChangedCallback} />
            <TableWithExtensions 
                columns={columns} 
                rowsData={rowsData} 
                pageType={"users"} 
                onFiltersChangedCallback={onFiltersChangedCallback} 
                onSortByCallback={onSortByCallback} 
                onSortOrderCallback={onSortOrderCallback}
                withCheckbox={true} 
                initSortBy={currentSorts}
                initSortOrder={currentSorts ? (currentSortOrder ? 'DESC' : 'ACS') : ''} 
                isLoading={isLoading} />
            {!isLoading &&<CustomPagination total={totalRows} perPage={rowsPerPage} currentPage={currentPage} onGoToPageCallback={onGoToPageCallback} />}
        </div>

    );
    
}

export default Users;