import React, { useState } from 'react';

//swiper
import { Swiper, SwiperSlide, } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
// styles and img
import '../../styles/EventDetailsPopover.scss';
import { getVideoByDeviceIdAndMessageId } from '../../service/fleetsenseApiClient';
import { Container } from 'react-bootstrap';
import DualVideoPlayer from './DualVideoPlayer';
import EventExapandableImage from './EventExapandableImage';

SwiperCore.use(Navigation)

const EventMedia = ({ eventData, showAlert }) => {

    const [currentIndex, setcurrentIndex] = useState(0)
    const roadVideoUrl = eventData.roadVideoCount && getVideoByDeviceIdAndMessageId(eventData.deviceID, eventData.messageID, "road");
    const cabinVideoUrl = eventData.cabinVideoCount && getVideoByDeviceIdAndMessageId(eventData.deviceID, eventData.messageID, "cabin");
    const buildSlides = () => {
        const slides = [];
        if (cabinVideoUrl) {
            slides.push({
                render: <DualVideoPlayer mainVideoUrl={cabinVideoUrl} secondaryVideoUrl={roadVideoUrl} />
            });
        }
        for (let i = 0; i < eventData.cabinImageCount; i++) {
            slides.push({
                render: <EventExapandableImage deviceId={eventData.deviceID}
                    messageId={eventData.messageID} type="cabin" index={i} showAlert={showAlert} />
            });
        }

        if (roadVideoUrl) {
            slides.push({
                render: <DualVideoPlayer mainVideoUrl={roadVideoUrl} secondaryVideoUrl={cabinVideoUrl} />
            });
        }
        for (let i = 0; i < eventData.roadImageCount; i++) {
            slides.push({
                render: <EventExapandableImage deviceId={eventData.deviceID}
                    messageId={eventData.messageID} type="road" index={i} showAlert={showAlert} />
            });
        }

        return slides;
    }
    const slides = buildSlides();
    return (<Container className="media-container">
        <Swiper
            loop={true}
            id="swiper"
            navigation
            slidesPerView={1}
            onActiveIndexChange={(swiper) => setcurrentIndex(swiper.activeIndex - 1)}
        >
            {slides.map((e, i) =>

                <SwiperSlide key={`${(currentIndex === i)} ${i}`}>
                    {e.render}
                    <div className='media-counter'>
                        {i + 1}/{slides.length}
                    </div>
                </SwiperSlide>

            )}
        </Swiper>
    </Container >
    )
}

export default EventMedia;