import React, { useRef } from 'react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
// styles and img
import FullScreenIcon from '../../assets/full-screen-icon.svg';
import '../../styles/EventDetailsPopover.scss';
import { getImageByMessageId } from '../../service/fleetsenseApiClient';

const EventExapandableImage = ({ deviceId, messageId, type, index, showAlert }) => {

    const fullScreenImg = useRef();
    const openImageFullScreen = async () => {
        if (fullScreenImg && fullScreenImg.current) {
            try {
                await fullScreenImg.current.requestFullscreen();
            } catch (err) {
                showAlert();
            }
        }
    }

    return (
        <React.Fragment>
            <button className="btn-gray img-container-btn" onClick={() => openImageFullScreen()}>
                <img src={FullScreenIcon} alt="full-screen" />
            </button>
            <img ref={fullScreenImg} src={getImageByMessageId(deviceId, messageId, type, index)} alt="" />
        </React.Fragment>
    )
};

export default EventExapandableImage;