import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import EventDetailsPopover from './ui/EventDetailsPopover';
// import { GoTriangleDown, GoTriangleUp} from 'react-icons/go';
import DevicesActions from './DevicesActions';
import UsersActions from './UsersActions';
import AddOwnerToast from './ui/AddOwnerToast';
import TextWithOverflowTooltip from './ui/TextWithOverflowTooltip';
import { getViewportWidth, getViewportHeight, getPageXOffset, getPageYOffset } from '../utils/viewportUtil';
import Spinner from 'react-bootstrap/Spinner';
import CustomModal from './ui/CustomModal';
import TableHeaders from './TableHeaders';
import { values, isNumber, isNull } from "lodash";
import "../styles/TableWithExtensions.scss";

//constants
const viewportWidth = getViewportWidth();
const viewportHeight = getViewportHeight();
const popoverWidth = 820;
const popoverHeight = 580;
const centerPopoverScreenSize = 1400;
//

const TableWithExtensions = (props) => {

    const { columns, rowsData, rowsFullData, onSortByCallback, onSortOrderCallback, onRemoveOwnerCallback, 
            popoverColumnIndex, ownerColumnIndex, withCheckbox, pageType, initSortBy, initSortOrder, isLoading, currentPage } = props;

    const [popoverLeftPosition, setPopoverLeftPosition] = useState(0);
    const [popoverTopPosition, setPopoverTopPosition] = useState(0);
    const [popoverArrowVerticalOffset, setPopoverArrowVerticalOffset] = useState(0);
    const [popoverIsInCenter, setPopoverIsInCenter] = useState(false);
    const [checkedRowId, setCheckedRowId] = useState(null);
    const [checkedRow, setCheckedRow] = useState({});
    const [currentSelectedEventIndex , setCurrentSelectedEventIndex ] = useState(null);
    const [sortBy, setSortBy] = useState(initSortBy);
    const [sortOrder, setSortOrder] = useState(initSortOrder);
    const [showAddOwnerOverlay, setShowAddOwnerOverlay] = useState(false);
    const [addOwnerOverlayPosition, setAddOwnerOverlayPosition] = useState({});
    const [currentDeviceToAddOwner, setCurrentDeviceToAddOwner] = useState("");
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [currentDeviceToRemoveOwnerIndex, setCurrentDeviceToRemoveOwnerIndex] = useState(null);

    useEffect(() => {
        if (!isNull(sortBy)) {
            onSortByCallback(sortBy);
        }
    }, [sortBy]);

    useEffect(() => {
        if (!isNull(sortOrder)) {
            onSortOrderCallback(sortOrder);
        }
    }, [sortOrder]);

    useEffect(() => {
        setCurrentSelectedEventIndex (null);
    }, [currentPage]);

    useEffect(() => {
       window.addEventListener('resize', determinePopoverPosition);

       return () => {
            window.removeEventListener('resize', determinePopoverPosition);
       }
    }, []);

    const calcWidthInPx = widthInPercentage => {
        return (viewportWidth * widthInPercentage) / 100;
    }

    const determinePopoverPosition = () => {
        if(window.innerWidth < centerPopoverScreenSize) {
            setPopoverIsInCenter(true);
        } else {
            setPopoverIsInCenter(false);
        }
    }

    const renderTable = (columns, data) => {

        return (
            <Table responsive bordered hover>
                <TableHeaders columns={columns} withCheckbox={withCheckbox} sortBy={sortBy} sortOrder={sortOrder} onSortBy={onSortBy} />
                {isLoading ? 
                <Spinner as="tbody" variant='primary' animation='border' style={{ position: 'fixed',  left: 'calc(50% - 16px)', marginTop: '10vh' }} />
                :
                <tbody>
                    {(data && data.length && data.map((dataRow, index) => {
                        const dataRowValues = values(dataRow);
                        const rowIndex = index;
                            return (
                                    <tr key={index} className={checkedRowId === `checkbox-${index}` ? "selected" : null}>
                                        {withCheckbox && 
                                            <td style={{ width: calcWidthInPx(1), maxWidth: calcWidthInPx(1) }}>
                                                <Form.Check id={`checkbox-${index}`} type='checkbox' onChange={e => onCheckboxChanged(index, e)} checked={checkedRowId === `checkbox-${index}`} />
                                            </td>}
                                        {dataRowValues.map((value, index) => {
                                                const columnWidth = calcWidthInPx(columns[index].width);
                                                const style = { maxWidth: `${columnWidth}px`, width: `${columnWidth}px` };
                                                switch (index) {
                                                    case popoverColumnIndex:
                                                        return <td key={index} className='clickable-with-shadow' onClick={e => onEventTypeClicked(rowIndex, e)} style={style}>
                                                            <TextWithOverflowTooltip text={value} />
                                                        </td>;
                                                    case ownerColumnIndex:
                                                        return value
                                                            ? <td key={index} className="owner" > 
                                                                <div>
                                                                    <TextWithOverflowTooltip text={value} />
                                                                </div>
                                                                <div className="remove-owner-icon clickable" onClick={() => onRemoveOwnerClicked(rowIndex)}>X</div>
                                                            </td>
                                                            : <td key={index} className='add-owner clickable'>
                                                                <span onClick={e => onAddOwnerClicked(dataRow.id, e)}>+ Associate</span>
                                                            </td>;
                                                    default:
                                                        return <td key={index} style={style}>
                                                            <TextWithOverflowTooltip text={value} />
                                                        </td>;
                                                }
                                            }
                                        )}
                                    </tr>
                            )
                    })) || null} 
                </tbody>}
            </Table>
        )
    }

    const onSortBy = (e, column) => {

        if (column !== sortBy) {
            setSortOrder('DESC');
            setSortBy(column);
        }
        else {
            switch (sortOrder) {
                case 'DESC':
                    setSortOrder('ACS');
                    setSortBy(column);
                    break;
                case 'ACS':
                    setSortOrder(null);
                    setSortBy(null);
                    break;
                default:
                    setSortOrder('DESC');
                    setSortBy(column);
            }
        }        
    }

    const onEventTypeClicked = (index, e) => {
        const el = e.target.closest('td');
        
        const { right, top } = el.getBoundingClientRect();  

        const leftPosition = viewportWidth - right >= popoverWidth ? right : viewportWidth - popoverWidth;
        const topPosition = (viewportHeight - popoverHeight) / 2;

        const arrowVerticalOffset = top - topPosition;

        setPopoverLeftPosition(leftPosition);
        setPopoverTopPosition(topPosition);
        setPopoverArrowVerticalOffset(arrowVerticalOffset);
        setCurrentSelectedEventIndex (index);
    }

    const onCheckboxChanged = (index, e) => {
        const checked = e.target.checked;
        const id = e.target.id;
        checked ? setCheckedRowId(id) : setCheckedRowId(null);
        checked ? setCheckedRow(rowsData[index]) : setCheckedRow({});
    }

    const onClosePopoverClicked = () => {
        setCurrentSelectedEventIndex (null);
    }

    const onAddOwnerClicked = (deviceId, e) => {
        const { left, bottom } = e.target.getBoundingClientRect();
        const pageYOffset = getPageYOffset();
        const pageXOffset = getPageXOffset();
 
        setAddOwnerOverlayPosition({ left: left + pageXOffset , bottom: bottom + pageYOffset });
        setCurrentDeviceToAddOwner(deviceId);  
        setShowAddOwnerOverlay(true);     
    }

    const onRemoveOwnerClicked = rowIndex => {
        setShowAreYouSureModal(true);   
        setCurrentDeviceToRemoveOwnerIndex(rowIndex);
    }
 
    return (
        <div className="table-with-extensions-container">
            {pageType === "devices" && <DevicesActions checkedRowId={checkedRowId} checkedRow={checkedRow} />}
            {pageType === "users" && <UsersActions checkedRowId={checkedRowId} checkedRow={checkedRow} />}
            {renderTable(columns, rowsData)}
            {isNumber(currentSelectedEventIndex) && <EventDetailsPopover 
                isInCenter={popoverIsInCenter}
                top={popoverTopPosition} 
                left={popoverLeftPosition} 
                arrowVerticalOffset={popoverArrowVerticalOffset}
                    eventData={{...rowsFullData[currentSelectedEventIndex ]}} 
                onCloseCallback={onClosePopoverClicked} />}  
            <AddOwnerToast 
                show={showAddOwnerOverlay} 
                left={addOwnerOverlayPosition.left} 
                top={addOwnerOverlayPosition.bottom} 
                onClose={() => setShowAddOwnerOverlay(false)} 
                deviceId={currentDeviceToAddOwner} />  
            <CustomModal 
                show={showAreYouSureModal} 
                headingText={"Remove Owner"} 
                text={currentDeviceToRemoveOwnerIndex && rowsData[currentDeviceToRemoveOwnerIndex] && 
                    `Are you sure you want to remove the owner ${rowsData[currentDeviceToRemoveOwnerIndex].ownerEmail} from ${rowsData[currentDeviceToRemoveOwnerIndex].id}?`} 
                onClose={() => setShowAreYouSureModal(false)} 
                onConfirmation={() => onRemoveOwnerCallback(currentDeviceToRemoveOwnerIndex)} />
        </div>
    );
}

export default TableWithExtensions;