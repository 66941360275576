import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { FiCheck } from 'react-icons/fi';
import { RiCameraLensLine, RiUpload2Line } from 'react-icons/ri';
import ButtonWithIcon from './ButtonWithIcon';
import CustomAlert from './CustomAlert';
import { captureFace, uploadDriverImage } from '../../service/fleetsenseApiClient';

import '../../styles/EnrollmentModal.scss';


const EnrollmentModal = props => {
    
    const { data, onClose } = props;
    const { id: deviceId, initDriverName } = data;

    const fileInput = useRef(null);

    const [ show, setShow ] = useState(true);
    const [ disableAllButtonsAndInput, setDisableAllButtonsAndInput ] = useState(false);
    const [ capturing, setCapturing ] = useState(false);
    const [ uploading, setUploading ] = useState(false);
    const [ driverName, setDriverName ] = useState(initDriverName);
    const [ showAlert, setShowAlert ] = useState(false);
    const [ alertDetails, setAlertDetails ] = useState({});
    const [ capturedImage, setCapturedImage ] = useState(null);
    const [ uploadedImage, setUploadedImage ] = useState(null);
    const [ imageBase64, setImageBase64 ] = useState('');
    

    useEffect(() => {
        if (imageBase64) {
            uploadApiCall();
        }
    }, [imageBase64]);


    const handleClose = (triggeredFromDoneButton = false) => {
        if((uploadedImage || capturedImage || disableAllButtonsAndInput) && !triggeredFromDoneButton)
        return;
        setShow(false);
        onClose();
    }

    const handleCapture = () => {
        setDisableAllButtonsAndInput(true);
        setCapturedImage(null);
        setUploadedImage(null);
        setCapturing(true);

        captureApiCall();
    }

    const uploadApiCall = async () => {
        
        try {
            await uploadDriverImage(deviceId, driverName, imageBase64);
            // setUploadedImage(imageBase64(URL.createObjectURL(selectedImage)));
            setAlertDetails({ variant: "success", message: "Image uploaded successfully." });
            setShowAlert(true);
            setUploading(false);
        }
        catch(e) {
            setUploadedImage(null);
            setDisableAllButtonsAndInput(false);
            setAlertDetails({ variant: "danger", message: "Unable to upload an image." });
            setShowAlert(true);
            setUploading(false);
        }        
    };

    const captureApiCall = async () => {
        try {
            const res =  await captureFace(deviceId, driverName);
            setCapturedImage(res.pngImage);
            setCapturing(false);
            setAlertDetails({ variant: "success", message: "Image captured successfully." });
            setShowAlert(true);
        }
        catch(e) {
            setAlertDetails({ variant: "danger", message: "Unable to capture an image." });
            setShowAlert(true);
            setDisableAllButtonsAndInput(false);
            setCapturing(false);
        }        
    };

    const handleUpload = async e => {
        setImageBase64(null);
        setUploading(true);
        setDisableAllButtonsAndInput(true);
        const reader = new FileReader();
        const selectedFile = e.target.files[0];

        reader.onload = () => {
            const imageBase64 = reader.result.substring(reader.result.indexOf(',') + 1, reader.result.length);
            setImageBase64(imageBase64);
        }

        reader.readAsDataURL(selectedFile);
        // setUploadedImage(selectedFile);
        setUploadedImage(URL.createObjectURL(selectedFile));
        fileInput.current.value = "";
    }

    const onDriverNameChanged = e => {
        setDriverName(e.target.value);
    }

    const onCloseErrorCallback = () => {
        setShowAlert(false);
    } 

    const handleRecapture = () => {
        setShowAlert(false);
        setDisableAllButtonsAndInput(true);
        setShowAlert(true);
        setCapturing(true);
        captureApiCall();
    }

    const handleReupload = () => {
        setCapturedImage(null);
        setCapturing(false);
        setUploading(false);

        fileInput.current.value = "";
        fileInput.current.click();
    }
        
    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <div className="driver-details-container">
                <Card>
                    <Card.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <span>Driver Name / ID:</span>
                                     <Form.Control disabled={disableAllButtonsAndInput} value={driverName} onChange={onDriverNameChanged} />
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            
            <div className="modal-body-container">
                <Card>
                    <CustomAlert 
                        variant={alertDetails.variant} 
                        show={showAlert} 
                        message={alertDetails.message}
                        onClose={onCloseErrorCallback}
                        timeout={3000} />
                    <Card.Body>
                        {(capturedImage || uploadedImage) && !uploading
                            ? <div className="img-container">
                                <img src={capturedImage ? `data:image/png;base64,${capturedImage}` : uploadedImage} alt="" /> 
                            </div>
                            : <div className="body-content">
                                <OverlayTrigger overlay={<Tooltip>Click to capture driver image</Tooltip>}>
                                    <ButtonWithIcon textSize={16} variant="secondary" width={180} text={capturing ? "Capturing..." : "Capture New"} disabled={ !driverName || (capturing || uploading)} onClick={handleCapture}>
                                        <RiCameraLensLine />
                                    </ButtonWithIcon>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Click to upload driver image</Tooltip>}>
                                    <div>
                                        <label htmlFor="file-upload" className="custom-file-upload">
                                            <ButtonWithIcon onClick={()=>{fileInput.current && fileInput.current.click()}} textSize={16} variant="primary" customStyles={{marginTop: 0}} width={180} text={uploading ? "Uploading..." : "Upload from PC"} disabled={ !driverName || (capturing || uploading)}>
                                                <RiUpload2Line />
                                            </ButtonWithIcon>
                                        </label>
                                    </div>
                                </OverlayTrigger>
                            </div>}  
                            <input id="file-upload" ref={fileInput} type="file" accept=".png,.jpg,.jpeg" style={{ visibility: 'hidden' }} onChange={handleUpload} />                 
                    </Card.Body>
                </Card>
            </div>


            <div className="modal-footer-container">
                <Modal.Footer>
                    {!(capturedImage || uploadedImage) && !disableAllButtonsAndInput && 
                        <ButtonWithIcon variant="third" text="Cancel" textSize={16} height={32} width={140} onClick={() => {handleClose(true)}} />}
                    {capturedImage && 
                        <ButtonWithIcon disabled={capturing} variant="secondary" text="Re-Capture" textSize={16} height={32} width={140} onClick={handleRecapture} >
                            <RiCameraLensLine />
                        </ButtonWithIcon>}
                    {uploadedImage && 
                        <ButtonWithIcon disabled={uploading} variant="secondary" text="Re-Upload" textSize={16} height={32} width={140} onClick={handleReupload} >
                            <RiCameraLensLine />
                        </ButtonWithIcon>}
                    {disableAllButtonsAndInput || capturedImage ? 
                        <ButtonWithIcon disabled={!capturedImage && !uploadedImage} variant="primary" text="Done" textSize={16} height={32} width={140} onClick={handleClose}>
                            <FiCheck />
                        </ButtonWithIcon>
                        :
                        null
                    }

                </Modal.Footer>
            </div>
        </Modal>
    )
}

export default EnrollmentModal;