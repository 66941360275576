import React, { useState, useEffect, useRef } from 'react';
import Popover from 'react-bootstrap/Popover';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { IconContext } from "react-icons";
import { BiX } from 'react-icons/bi';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import CustomAlert from '../ui/CustomAlert';

// styles and img
import '../../styles/EventDetailsPopover.scss';
import EventDetails from './EventDetails';
import EventMap from './EventMap';
import EventMedia from './EventMedia';



const EventDetailsPopover = ({ top, left, eventData, arrowVerticalOffset, onCloseCallback, isInCenter }) => {

  const [showAlert, setShowAlert] = useState(false);
  const popover = useRef();
  const hasMedia = !!(eventData.cabinImageCount || eventData.cabinVideoCount || eventData.roadVideoCount || eventData.roadImageCount);
  const popoverStyle = {
    top: `${top}px`,
    left: `${left}px`,
    maxWidth: 'none',
    boxShadow: "0px 3px 6px #0000005C",
    border: "2px solid #8EA8C8",
    width: "45vw",
    height: hasMedia ? "75vh" : "65vh"
  };


  useEffect(() => {

    const arrowEl = document.querySelector('.popover .arrow');
    if (isInCenter) {
      arrowEl.style.display = 'none';
    } else {
      arrowEl.style.display = 'block';
      arrowEl.style.top = `${arrowVerticalOffset}px`;
    }
  }, [eventData, arrowVerticalOffset, isInCenter]);


  useOutsideClick(popover, (e) => {
    onCloseCallback();
  });


  return (
    <Popover ref={popover} id="popover-basic"
      style={isInCenter ? { ...popoverStyle, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } : popoverStyle}>
      <Popover.Content>
        <CustomAlert
          variant={'danger'}
          show={showAlert}
          message={'Unable to scale image to full screen'}
          onClose={() => setShowAlert(false)} />
        <Container className='container' >
          <Row className='h-50 p-2' >
            <Col xs={hasMedia ? 6 : 12}>
              <EventDetails eventData={eventData} />
            </Col>
            {hasMedia && <Col xs={6}>
              <EventMap lat={eventData.lastKnownLocationLat} lng={eventData.lastKnownLocationLong} />
            </Col>}
          </Row>
          <Row className='h-50 p-2'>
            {hasMedia ? <EventMedia eventData={eventData} showAlert={() => setShowAlert(true)} />
              : <EventMap lat={eventData.lastKnownLocationLat} lng={eventData.lastKnownLocationLong} />}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  )
}

export default EventDetailsPopover;