import React, { useState, useRef } from 'react';
import {AiOutlineVerticalLeft, AiOutlineLeft, AiOutlineVerticalRight, AiOutlineRight} from 'react-icons/ai';
import { isNumber, debounce } from 'lodash';

import "../../styles/CustomPagination.scss";

const CustomPagination = (props) => {

const { total, perPage, currentPage, onGoToPageCallback } = props;
const numPages = total && isNumber(total) ? Math.ceil(total / perPage) : 0;

const inputEl = useRef(null);
const [lastCurrentPage, setLastCurrentPage] = useState(currentPage);


    const onFirstClicked = e => {
        if (inputEl.current.value !== 1) {
            inputEl.current.value = 1;
            setLastCurrentPage(inputEl.current.value);
            onGoToPageCallback(inputEl.current.value);
        }
    }

    const onPrevClicked = e => {
        const currPage = parseInt(inputEl.current.value);
        // setLastCurrentPage(currPage);
        if (inputEl.current.value > 1) {
            inputEl.current.value = currPage - 1;
            setLastCurrentPage(inputEl.current.value);
            onGoToPageCallback(inputEl.current.value);
        }
    }

    const onNextClicked = e => {
        const currPage = parseInt(inputEl.current.value);
        // setLastCurrentPage(currPage);
        if (currPage !== numPages) {
            inputEl.current.value = currPage + 1;
            setLastCurrentPage(inputEl.current.value);
            onGoToPageCallback(inputEl.current.value);
        }
    }

    const onLastClicked = e => {
        // setLastCurrentPage(parseInt(inputEl.current.value));
        if (inputEl.current.value !== numPages) {
            inputEl.current.value = numPages;
            setLastCurrentPage(inputEl.current.value);
            onGoToPageCallback(inputEl.current.value);
        }
    }

    const onPaginationInputChange = e => {
        const inputValue = e.target.value;
        const parsedInputValue = parseInt(inputValue);

        if ((isNaN(parsedInputValue) && inputValue !== '') || parsedInputValue < 1 || parsedInputValue > numPages) {
            inputEl.current.value = lastCurrentPage;
        }

        else {
            inputEl.current.value = inputValue;
            debounce(() => {
                if (inputEl.current && inputEl.current.value !== '') {
                    setLastCurrentPage(inputEl.current.value);
                    onGoToPageCallback(inputEl.current.value);
                }                
            }, 1000)();
        }
    }

    return (
        <div>
        { (total === undefined) ? 
            <div align="center">No Results</div>
            :
            <div className="pagination">
                <div className="pagination-item clickable">
                    <AiOutlineVerticalRight size={15} onClick={onFirstClicked} />
                </div>
                <div className="pagination-item clickable">
                    <AiOutlineLeft size={15} onClick={onPrevClicked} />
                </div>
                <div className="pagination-item">
                    <span>Page</span>
                </div>
                <div className="pagination-item clickable">
                    <input type="number" className="pagination-input" defaultValue={currentPage} ref={inputEl} onChange={onPaginationInputChange} />
                </div>
                <div className="pagination-item">
                    <span>of {numPages}</span>
                </div>
                <div className="pagination-item clickable">
                    <AiOutlineRight size={15} onClick={onNextClicked} />
                </div>
                <div className="pagination-item clickable">
                    <AiOutlineVerticalLeft size={15} onClick={onLastClicked}/>
                </div>   
                <div className="pagination-item">
                    <span>[ total: {total} ]</span>
                </div>         
            </div>
    }
    </div>)
}

export default CustomPagination;