import React, { useState } from 'react';
import './App.scss';
import Login from './pages/Login';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Layout from './pages/Layout';
import ProtectedRoute from './components/route/ProtectedRoute';
import { AuthContext } from './context/auth';
import { isNil, keys, includes } from 'lodash';
import jwt_decode from 'jwt-decode';
import Footer from './components/Footer';
function App() {

  const extractEmailFromToken = token => {
    const decodedToken = jwt_decode(token);
    const userNameKey = keys(decodedToken).filter(key => includes(key, "name"))[0];

    return decodedToken[userNameKey];
  }

  const existingToken = localStorage.getItem("token");
  const existingLoggedInUserName = existingToken ? extractEmailFromToken(existingToken) : null;
  const [isLoggedIn, setIsLoggedIn] = useState(existingToken);
  const [loggedInUserName, setLoggedInUserName] = useState(existingLoggedInUserName);

  const setLogInStatus = () => {
    const token = localStorage.getItem("token");
    
    setIsLoggedIn(!isNil(token));
    setLoggedInUserName(extractEmailFromToken(token));
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn: setLogInStatus }}>
      <div className="fleetsense-ui">
        <Router>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/login" />
              </Route>
              <Route path="/login" component={Login} />
              {/* <Route path="/login" render={props => <Login {...props} />} /> */}
              <ProtectedRoute path="/app" component={Layout} currentUser={loggedInUserName} />
            </Switch>
        </Router>
        <Footer></Footer>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
