import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from "react-icons";
import '../../styles/IconWithLabel.scss';

const IconWithLabel = (props) => {

    const { size, color, label, children} = props;

    return(
        <div className="icon-with-label">
            <IconContext.Provider value={{ color: color, size: size }}>
                <div className="icon">
                    {children}
                </div>
            </IconContext.Provider>
            <div className="label" style={{ color: color }}>
                {label}
            </div>
        </div>
    ) 
}

IconWithLabel.defaultProps = {
    size: 18,
    color: 'white',
    label: 'label'
};

IconWithLabel.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    label: PropTypes.string
};


export default IconWithLabel;