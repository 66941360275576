import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import IconWithLabel from './ui/IconWithLabel';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import EnrollmentModal from './ui/EnrollmentModal';
import { pick } from 'lodash';

import '../styles/DevicesActions.scss';

const DevicesActions =  ({ checkedRowId, checkedRow }) => {

    const barsHeight = 2.2;

    const [actionsIsOpen, setActionsIsOpen] = useState(false);
    const [actionsHeight, setActionsHeight] = useState(barsHeight);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);

    const selectedDriverData =  pick(checkedRow, [ 'id','driverName', 'deviceId']);

    const onActionsClicked = () => {
        setActionsIsOpen(!actionsIsOpen);
        setActionsHeight(actionsIsOpen ? actionsHeight / 1.5 : actionsHeight * 1.5);  
    }

    const onEnrollmentClicked = e => {
        setShowEnrollmentModal(true);
    }

    const onEnrollmentModalClosedCallback = e => {
        setShowEnrollmentModal(false);
    }

    return (
        <div className="table-actions-container">
            <Card style={{height: `${actionsHeight}rem`}} >
                <div className="actions-label" onClick={onActionsClicked}>
                    {actionsIsOpen
                        ? <IconWithLabel label="Actions" color={"#92D050"}><GoTriangleUp /></IconWithLabel>
                        : <IconWithLabel label="Actions" color={"#92D050"}><GoTriangleDown /></IconWithLabel>}
                </div>
                {actionsIsOpen && 
                <div className="action-buttons-container">
                    <Button variant="secondary" value="Enrollment" onClick={onEnrollmentClicked} disabled={!checkedRowId}>Enrollment</Button>
                    {!checkedRowId ? <span><b>Check row to perform action</b></span> : null}
                </div>}
            </Card>              
            {showEnrollmentModal && <EnrollmentModal data={selectedDriverData} onClose={onEnrollmentModalClosedCallback} centered={true} />}
        </div>
    )
}

export default DevicesActions;