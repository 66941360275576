import React, { useRef } from 'react';
import { apiKey } from '../../utils/googleApiUtil';
import useScript from '../../hooks/useScript';
 
const SimpleMap =  ({ lat, lng, width, height}) => {

  const mapRef = useRef(null);

  const initMap = () => {
    if (window.google) {
      const map = new window.google.maps.Map(mapRef.current, {
        center:  new window.google.maps.LatLng(lat, lng),
        zoom: 14,
        controlSize: 28,
        iconSize: 12
      });

      new window.google.maps.Marker({
        position: { lat, lng },
        map: map,
      });
    }
  }
  
  useScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`, initMap);

  return (
    <div ref={mapRef} style={{ height: '100%', width: '100%' }} />
  );

}
 
export default SimpleMap;