import React, { useContext, useState, useRef, useEffect } from 'react';
import ButtonWithIcon from './ButtonWithIcon';
import { IconContext } from "react-icons";
import { BiExport } from 'react-icons/bi';
import { CSVLink } from "react-csv";
import TableDataContext from '../../context/tableDataContext';
import Spinner from 'react-bootstrap/Spinner';
import { getCsvHeaders } from '../../utils/exportCsvUtil';

import '../../styles/PageTitle.scss';
import { convertISODateStringToFormattedDateString } from '../../utils/dateUtil';

const PageTitle = props => {

    const { title, variant, children } = props;
    const [ exportData, setExportData ] = useState([]);
    const [ isDataReady, setIsDataReady ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ exportCsvHeaders, setExportCsvHeaders ] = useState(false);
    
    const csvLink = useRef(null);

    const { fetchCallback, fetchCallbackParams } = useContext(TableDataContext);

    useEffect(() => {
        if (exportData && exportData.length > 0 && !isDataReady) {
            setIsDataReady(true);
        }
    }, [exportData]);

    useEffect(() => {
        if (isDataReady) {
            csvLink.current.link.click();
            setIsLoading(false);
            setIsDataReady(false);
        }
    }, [isDataReady]);

    const onExportClicked = async () => {
        setIsLoading(true);
        const res = await fetchCallback(...fetchCallbackParams);
        const formttedData = res.map( dataItem => {return {...dataItem, speed: Math.round(dataItem.speed * 3.6)}})
        setExportCsvHeaders(getCsvHeaders(title));
        setExportData(formttedData);
    }

    return (
        <div className="page-title-container">
            <div className="page-title">
                <div className="page-title-icon">
                    <IconContext.Provider value={{ size: 20 }}>
                        {children}
                    </IconContext.Provider>
                </div>
                <div className={`page-title-text ${variant}`} style={{fontSize: 20}}>
                    {title}
                </div>
            </div>

            <CSVLink 
                ref={csvLink}
                data={exportData} 
                enclosingCharacter={''}
                headers={exportCsvHeaders ? exportCsvHeaders : null}
                filename={`${title.toLowerCase()}_${convertISODateStringToFormattedDateString(Date.now())}.csv`} >
            </CSVLink>

            {isLoading ? 
                <Spinner variant='primary' size='sm' animation='border' style={{ margin: 'auto 2vw auto 0' }} /> :
                fetchCallback && <div className="export">
                    <ButtonWithIcon variant="white" text="Export" width={100} height={32} onClick={onExportClicked}>
                        <BiExport />
                    </ButtonWithIcon>
                </div>}                
        </div>
    )
}

export default PageTitle;