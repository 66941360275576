import React, { useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const hiddenSpanStyles = {
    visibility:'hidden',
    zIndex:'-1',
    position:'absolute',
    fontSize: '14px',
    whiteSpace: 'nowrap'
}

const WithTooltip = ({children, text, sizeCalculationOffset = 32, customStyles, externalConditionShowTooltip, placement = "bottom"}) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const innerTooltipRef = useRef(null);
    const tooltipWrapperRef = useRef(null);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    const textIsOverflowing = (innerTooltipRef, tooltipWrapperRef) => {
        return innerTooltipRef.current.clientWidth > tooltipWrapperRef.current.clientWidth - sizeCalculationOffset
    }

    return (
        <OverlayTrigger
            show={showTooltip && (externalConditionShowTooltip || textIsOverflowing(innerTooltipRef, tooltipWrapperRef)) }
            placement={placement}
            overlay={renderTooltip}
            trigger={['hover', 'focus']}
        >
            <div
                className="custom-tooltip"
                ref={tooltipWrapperRef}
                onMouseEnter={() => {setShowTooltip(true)}}
                onMouseLeave={() => {setShowTooltip(false)}}
                onClick={() => {setShowTooltip(false)}}
                style={{...customStyles, position:'relative'}}
            >
                {children}

                <span ref={innerTooltipRef} style={hiddenSpanStyles}>
                    {text}
                </span>
            </div>
        </OverlayTrigger>
    )
}

export default WithTooltip;
