import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import IconWithLabel from './ui/IconWithLabel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import { FiCheck } from 'react-icons/fi';
import { BiReset, BiX } from 'react-icons/bi';
import ButtonWithIcon from './ui/ButtonWithIcon';
import CustomAlert from './ui/CustomAlert';
import { upsertUser, removeUser } from '../service/fleetsenseApiClient';
import CustomModal from './ui/CustomModal';
import { isEmpty } from 'lodash';

import '../styles/UsersActions.scss';
import SetPasswordToast from './ui/SetPasswordToast';
import { passwordIsInvalid, invalidPasswordMessage } from '../utils/passwordValidationUtil';
import { setPasswordToUser } from '../service/fleetsenseApiClient';

const UsersActions = ({ checkedRowId, checkedRow }) => {

    const barsHeight = 2.2;

    const [ actionsIsOpen, setActionsIsOpen ] = useState(false);
    const [ actionsHeight, setActionsHeight ] = useState(barsHeight);
    const [ addUserDetails, setAddUserDetails ] = useState({});
    const [ addUserTriggered, setAddUserTriggered ] = useState(false);
    const [ removeUserTriggered, setRemoveUserTriggered ] = useState(false);
    const [ showSetPasswordToast, setShowSetPasswordToast ] = useState(false);
    const [ setPasswordToastPosition, setSetPasswordToastPosition ] = useState({});
    const [ isAddingUser, setIsAddingUser ] = useState(false);
    const [ isRemovingUser, setIsRemovingUser ] = useState(false);
    const [ showAlert, setShowAlert] = useState(false);
    const [ alertDetails, setAlertDetails ] = useState({});
    const [ showAreYouSureModal, setShowAreYouSureModal ] = useState(false);
 
    useEffect(() => {
        if(!addUserTriggered)
            return;

        const isPasswordValid = !passwordIsInvalid(addUserDetails.password);

        if (addUserTriggered && !isEmpty(addUserDetails) &&
            addUserDetails.email && addUserDetails.email !== "" &&
            addUserDetails.username && addUserDetails.username !== "" &&
            addUserDetails.role && addUserDetails.role !== "" &&
            addUserDetails.password && isPasswordValid ) {
            setIsAddingUser(true);
            addUserApiCall();
        } else if(!isPasswordValid) {
            setAlertDetails({ variant: "danger", message: invalidPasswordMessage });
            setShowAlert(true);
        }

        setAddUserTriggered(false);
    }, [addUserTriggered]);

    useEffect(() => {
        if (removeUserTriggered && !isEmpty(checkedRow)) {
            setIsRemovingUser(true);
            removeUserApiCall();
        }

        setRemoveUserTriggered(false);
    }, [removeUserTriggered]);

    const addUserApiCall = async () => {
        try {
            await upsertUser(addUserDetails.username, addUserDetails.email, addUserDetails.role);
            await setPasswordToUser(addUserDetails.email, addUserDetails.password);
            setAlertDetails({ variant: "success", message: "User added successfully." });
            setShowAlert(true);
        } catch (e) {
            setAlertDetails({ variant: "danger", message: "Unable to add user." });
            setShowAlert(true);
        }

        setIsAddingUser(false);

    };

    const removeUserApiCall = async () => {
        try {
            await removeUser(checkedRow.email);
            setAlertDetails({ variant: "success", message: "User removed successfully." });
            setShowAlert(true);
        } catch (e) {
            setAlertDetails({ variant: "danger", message: "User cannot be deleted since there are devices associated with this user. Please change device association first." });
            setShowAlert(true);
        }

        setIsRemovingUser(false);
    };

    const onActionsClicked = () => {
        setActionsIsOpen(!actionsIsOpen);
        setActionsHeight(actionsIsOpen ? barsHeight : 7.5); 
    }

    const onUserDetailChanged = (userDetail, e) => {
        const value = e.target.value;
        setAddUserDetails({ ...addUserDetails, [userDetail]: value });
    }

    const onAddUserClicked = () => {
        setAddUserTriggered(true);
    }

    const onSetPasswordClicked = e => {
        const { offsetLeft, offsetTop, offsetHeight } = e.currentTarget;
        setSetPasswordToastPosition({ left: offsetLeft , top: offsetTop + offsetHeight});
        setShowSetPasswordToast(true);
    }

    const onRemoveUserClicked = () => {
        setShowAreYouSureModal(true);
    }

    const onCloseCallback = () => {
        setShowAlert(false);
        refreshPage();
    } 

    const refreshPage = () => {
        if(alertDetails.message !== invalidPasswordMessage)
            window.location.reload(false);
    }

    return (
        <div className="table-actions-container">
            <Card style={{height: `${actionsHeight}rem`}} >
                <div className="actions-label" onClick={onActionsClicked}>
                    {actionsIsOpen
                        ? <IconWithLabel label="Actions" color={"#92D050"}><GoTriangleUp /></IconWithLabel>
                        : <IconWithLabel label="Actions" color={"#92D050"}><GoTriangleDown /></IconWithLabel>}
                </div>
                {actionsIsOpen && 
                <div className="action-container">
                    <div className="add-user-action">
                        <Form>
                            <div className="add-user-title">Add User</div>
                            <Form.Row>
                                <Col>
                                    <div className="add-user-form-group">
                                        <span>Username:</span>
                                        <Form.Control size="sm" style={{fontSize: '16px'}} onChange={e => onUserDetailChanged("username", e)} />
                                    </div>
                          
                                    <div className="add-user-form-group">
                                        <span>Password:</span>
                                        <Form.Control autoComplete="new-password" type="password" size="sm" style={{fontSize: '16px'}} onChange={e => onUserDetailChanged("password", e)} />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="add-user-form-group">
                                        <span>Email:</span>
                                        <Form.Control size="sm" style={{fontSize: '16px'}} onChange={e => onUserDetailChanged("email", e)} />
                                    </div>
                         
                                    <div className="add-user-form-group">
                                        <span>Role:</span>
                                        <Form.Control as="select" size="sm" style={{fontSize: '16px'}} onChange={e => onUserDetailChanged("role", e)} >
                                            <option></option>
                                            <option>Administrator</option>
                                            <option>Installer</option>
                                            <option>User</option>
                                        </Form.Control>
                                    </div>  
                                </Col>                                
                                <ButtonWithIcon 
                                    variant="primary" 
                                    text={ isAddingUser ? "Adding a user..." : "Add"} 
                                    textSize={isAddingUser ? 14 : 18} 
                                    height={32} 
                                    disabled={isAddingUser} 
                                    onClick={onAddUserClicked}
                                    >
                                    <FiCheck />
                                </ButtonWithIcon>
                            </Form.Row>
                        </Form>
                    </div>
                    <div className="other-actions">
                        <div className="other-title">Other</div>
                        <ButtonWithIcon variant="secondary" text="Set Password" disabled={!checkedRowId} width={180} height={32} onClick={onSetPasswordClicked}>
                            <BiReset />
                        </ButtonWithIcon>
                        <SetPasswordToast 
                            email={checkedRow.email}
                            show={showSetPasswordToast} 
                            left={setPasswordToastPosition.left} 
                            top={setPasswordToastPosition.top} 
                            onClose={() => setShowSetPasswordToast(false)} />
                        <ButtonWithIcon 
                            variant="third" 
                            text={ isRemovingUser ? "Removing a user..." : "Remove"} 
                            textSize={isRemovingUser ? 12 : 18} 
                            disabled={!checkedRowId || isRemovingUser} 
                            height={32} 
                            onClick={onRemoveUserClicked}
                            >
                            <BiX />
                        </ButtonWithIcon>
                    </div>
                </div>}
            </Card>  

            <CustomAlert 
                variant={alertDetails.variant} 
                show={showAlert} 
                message={alertDetails.message} 
                onClose={onCloseCallback}
                timeout={2000} />     

            <CustomModal 
                show={showAreYouSureModal} 
                headingText={"Remove User"} 
                text={checkedRow && checkedRow.email && `Are you sure you want to remove the user ${checkedRow.userName}?`} 
                onClose={() => setShowAreYouSureModal(false)} 
                onConfirmation={() => setRemoveUserTriggered(true)} />

        </div>
    )
}

export default UsersActions;
